@font-face {
  font-family: 'Inter';
  font-weight: 100 900;
  font-display: block;
  font-style: normal;
  font-named-instance: 'Regular';
  src: url('./fonts/Inter/static/Inter-Regular.ttf') format('ttf');
}

@tailwind base;
@tailwind components;
@tailwind utilities;

#__next {
  min-height: 100%;
}

.stars {
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  -webkit-justify-content: center;
  position: relative;
  margin-top: 5px;
}

.star {
  position: relative;
  height: 50px;
  width: 50px;
  flex-shrink: 0;
  cursor: pointer;
}

.star svg {
  width: 50px !important;
  height: 50px !important;
  fill: #dddddd;
  transition: all 75ms ease-in-out;
}

.star .left,
.star .right {
  position: absolute;
  width: 25px;
  height: 50px;
  top: 0;
  overflow: hidden;
}

.star .left {
  left: 0;
}

.star .right {
  right: 0;
}

.star-half.clicked > svg {
  fill: #3C1355!important;
}

.star-half.hover > svg {
  fill: #3C135570;
}

.star-half.hoverImportant > svg {
  fill: #3C135570!important;
}

.scrollwrapper:after{
  background: linear-gradient(to right,#fff 20%,rgba(255,255,255,0) 80%);
  height: 4rem;
  width: 50px;
  content: "";
  position: absolute;
  pointer-events: none;
}
.scrollwrapperinside:before{
  background: linear-gradient(to left,#fff 20%,rgba(255,255,255,0) 80%);
  height: 4rem;
  width: 50px;
  content: "";
  position: absolute;
  justify-self: end;
  z-index: 99999999999999;
  pointer-events: none;
}
.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  padding-top: 0px;
  padding-bottom: 0px;
}